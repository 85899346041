<template>
  <div>
    <section>
      <Hero>
        <div v-if="loading">
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Ihre Anfrage wird verarbeitet...</strong>
          </div>
        </div>
        <div v-else>
          <div class="container">
            <HeadText :titletext="'Newsletterabmeldung'" :subtext="''"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3 pb-3">
            <b-row v-if="this.error">
              <b-col md="12" class="text-left">
                <div class="alert alert-danger" role="alert">
                  <p>Es ist ein Fehler aufgetreten.</p>
                  <p class="fs-7">{{ error }}</p>
                </div>
              </b-col>  
            </b-row>
            <b-row v-else>
              <b-col md="12" class="text-left">
                <div class="alert alert-info" role="alert">
                  <p>Sie wurden erfolgreich abgemeldet.</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";

export default {
  name: "Unsubscribe",
  components: {Hero, HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
    }
  },
  data() {
    return {
      loading: true,
      error: null,
    }
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    decodeToken( token ) {
      if ( !token ) {
        return undefined;
      }
      /*
       byte[] decodedBytes = Base64.getDecoder().decode( token );
        String parameterString = new String( decodedBytes );

        return new ListUnsubscribeParameters( Arrays.stream(parameterString.split("&"))
                .map(s -> s.split("=", 2))
                .collect( Collectors.toMap( a -> a[0], a -> a.length > 1 ? a[1] : "")) );
       */
      return atob( token );
    },
    sendRequest( token, decodedToken ) {
      if ( !token || !decodedToken || !(decodedToken.includes("guid=") || decodedToken.includes("login=")) ) {
        this.error = 'Ungültiger Link.';
        this.loading = false;
        return;
      }
      var url;
      if ( decodedToken.includes("guid=") ) {
        url = this.$store.getters.getAllEnvis.API_STAMMDATEN_UNSUBSCRIBE  
      } else if ( decodedToken.includes("login=") ) {
        url = this.$store.getters.getAllEnvis.API_LOGINS_UNSUBSCRIBE
      } 
      url = url.replace("{subsite}",this.$store.getters.getSubsite).replace("{token}",token);
      console.log( url );
      axios.post( url )
          .then( () => {
            this.loading = false;
          })
          .catch(error => {
            console.log( error );
            this.loading = false;
            if ( error.response.status === 400 ) {
              this.error = 'Ungültiger Link.';
            }
            else if ( error.response.status === 401 ) {
              this.warning = 'Sie haben keine Berechtigung zum Abmelden.';
            }
            else {
              this.error = error;
            }
          })
    },
  },
  mounted() {
    this.loading = true;
    this.sendRequest( this.$route.query.token, this.decodeToken( this.$route.query.token ) );
  }
}
</script>

<style scoped>

</style>